<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <vuestic-modal :isOpen="isShowModal" @cancel="isShowModal = false" :okShown="false" :cancelShown="false" :force="true"
      cancelClass="btn btn-danger" xl>
      <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="sendMessageActionForm">
        <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm">
          <div class="row">
            <div class="col-12 mb-4">
              <text-input v-model="block.name"></text-input>
            </div>
            <div class="col-md-12 mb-4">
              <label class="lelabel">Content</label>
              <validation-provider rules="required" v-slot="{}" name="Message">
                <TextareaEmojiPicker v-model="block.options.content" :focused="true" :rows="7" :enabledMms="false"
                  ref="textareaEmoji" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
                  :optText="optText" :enabledEmoji="false" />
              </validation-provider>
            </div>
            <div class="col-md-12">
              <label class="input-label has-tool-tip">Trigger Option <a v-b-tooltip.hover
                  title="If First Time selected, we will send an alert to the users only for the new contacts."
                  class="fa fa-question-circle tooltip-icon"></a></label>
              <div class="d-inline-block" style="width: 400px">
                <vuestic-switch v-model="block.options.allLeads">
                  <span slot="trueTitle">All Leads</span>
                  <span slot="falseTitle">First Time</span>
                </vuestic-switch>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <h5>Notifcation Emails and Phone Numbers</h5>
              <div v-for="(user, index) in block.options.users" :key="`user-${index}`" class="row">
                <div class="col-md-5 d-flex align-items-center">
                  <div class="flex-fill">
                    <text-input v-model="user.email" name="email" label="Email" />
                  </div>
                  <vuestic-switch v-model="user.enable_email" class="ml-2" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-5 d-flex align-items-center">
                  <div class="flex-fill">
                    <phone-number-input v-model="user.phone" label="Phone" name="phone" :required="false" />
                  </div>
                  <vuestic-switch v-model="user.enable_phone" class="ml-2" :offcolor="true">
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-2 pt-2 ">
                  <button class="btn btn-danger mt-3" @click="deleteUser(index)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>

              <button class="btn btn-primary" @click="newUser" :disabled="newButtonDisabled">
                New row
              </button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>
    
<script>
import TextareaEmojiPicker from '../../../../common/TextareaEmojiPicker'
import SMSLength from '../../../../common/SMSLength'

export default {
  components: {
    TextareaEmojiPicker,
    SMSLength,
  },

  props: {
    block: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      processing: false,
      isShowModal: false,
    }
  },

  computed: {
    newButtonDisabled() {
      let disabled = false
      this.block.options.users.forEach(item => {
        if (item.enable_email && !item.email || item.enable_phone && !item.phone || !item.email && !item.phone)
          disabled = true
      })
      return disabled
    },

    optText() {
      return this.$store.getters["auth/optText"];
    },

    maxLength() {
      return 1600 - (this.optText ? this.optText.length : 0);
    },
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }

    if (!this.block.options.users.length) {
      this.newUser()
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    newUser() {
      this.block.options.users.push({ email: null, phone: null, enable_email: true, enable_phone: true })
    },

    deleteUser(index) {
      this.block.options.users.splice(index, 1)
    },
  },
}
</script>